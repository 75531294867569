<template>
  <!-- 合同列表附件 -->
  <div class="attachments">
    <Header back="返回" title="合同列表" index="首页" titleOne="合同管理" titleTwo="合同列表" beforeTitle="附件" />
    <div
      class="content"
      v-loading="loading"
      element-loading-text="附件上传中···"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    >
      <h1>附件详情</h1>
      <ul>
        <li>
          <span>企业名称</span>
          <p>{{ this.company_name }}</p>
        </li>
        <li>
          <span>合同名称</span>
          <p>{{ this.contract_name }}</p>
        </li>
      </ul>
      <div class="ImportTop">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="#"
          :http-request="httpRequest"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button type="primary" class="ChooseFile">请上传合同附件</el-button>
          <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
        </el-upload>
      </div>
      <el-table :data="fileList" style="width: 100%;margin-top:30px">
        <el-table-column prop="name" label="附件名称"></el-table-column>
        <el-table-column prop="create_time" label="上传时间"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button class="cz edit" @click="lookFile(scope.row)" type="text">查看</el-button>
            <el-button class="cz edit" type="text">
              <el-link :underline="false" :href="userSite + scope.row.url">下载</el-link>
            </el-button>
            <el-button class="cz del" @click="delFile(scope.row.id)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="上传附件" :visible.sync="dialogTableVisible">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="#"
          :http-request="httpRequest"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
          :auto-upload="false"
        ></el-upload>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// import WebOfficeSDK from '../../utils/web-office-sdk-solution-v1.1.24.es.js'
import axios from "axios";
import site from "../../components/global.vue";
export default {
  inject: ["reload"],
  data () {
    return {
      token: '',
      dialogTableVisible: false,
      loading: false,
      userSite: site.userSite,
      company_name: "",//企业名称
      contract_name: "",//合同名称
      tableData: [],
      fileList: [],
      patentParams: {
        status: "0",
      },
    };
  },
  created () {
    this.getFileList()
    this.token = localStorage.getItem('token')
    // console.log(WebOfficeSDK)
  },
  methods: {
    getFileList () {
      this.axios
        .get("/api/contract/view_file", {
          params: {
            contract_id: this.$route.query.id,
          },
        })
        .then((res) => {
          console.log('合同列表附件查看', res);
          this.company_name = res.data.company_name;
          this.contract_name = res.data.contract_name;
          this.fileList = res.data.file_list;
        });
    },
    httpRequest (param) {
      let file = param.file;
      const form = new FormData();
      console.log(form);
      form.append("file", file);
      form.append("token", this.token);
      form.append("model", 'contract');
      form.append("model_id", this.$route.query.id);
      this.upload("/api/file/upload_file", form).then((res) => {
        // this.$router.push("/patent/project");
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.loading = false;
          this.getFileList()
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
          this.loading = false;
        }
      });
    },
    upload (url, params) {
      this.loading = true;
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    lookFile (row) {
      let file_id = row.id
      const routeData = this.$router.resolve({
        path: '/wpsfile',      //跳转目标窗口的地址
        query: {
          file_id    //括号内是要传递给新窗口的参数
        }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push({ path: '/wpsfile', query: { file_id: row.id } })
      // window.open(this.userSite + row.url)
      // console.log(row, this.userSite + row.url)
    },
    uploadFile (row) {
      console.log(this.userSite + this.fileList.url)
    },
    delFile (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete("/api/file/delete_file", {
          params: {
            id: id,
          },
        }).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
        this.getFileList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
  },
};
</script>

<style scoped="scoped">
.attachments ul li {
  margin-top: 20px;
}

.attachments ul li span {
  color: #cbcfe3;
  margin-right: 20px;
  float: left;
}

.attachments ul li p {
  display: inline-block;
  width: 80%;
  margin: 0;
  color: #909399;
}

.upload-demo {
  display: inline-block;
}

.ImportTop {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.el-upload-list {
  display: inline-block;
}

.el-upload-list__item-name {
  margin-top: 20px;
}
.ImportTop span {
  color: #a6abc7;
}
div/deep/.el-button--text {
  background: #f2f3f9;
  padding: 7px 15px !important;
  font-weight: bold;
}
div/deep/.el-form-item {
  margin: 0;
}
div/deep/.el-form-item__content {
  margin: 0;
  margin-right: 20px;
}
div/deep/.el-icon-loading {
  font-size: 20px;
  color: #fff;
}
div/deep/.el-loading-text {
  color: #fff;
  font-size: 12px;
}
.submitUpload {
  display: block;
  margin: 15px auto;
}
div/deep/.el-upload {
  display: flex;
  align-items: center;
}
.el-select {
  margin-right: 20px;
}
.upload-demo {
  width: 100%;
}
div/deep/.el-dialog__body {
  padding: 20px !important;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
</style>
